<template>
  <router-view class="container post-offer">
    <ProgressBar slot="ProgressBar" :percent="postOfferPercent" />
  </router-view>
</template>

<script>
import ProgressBar from '@/components/common/ProgressBar'

export default {
  name: 'PostOffer',
  components: {
    ProgressBar,
  },
  data() {
    return {
      postOfferPercent: 0,
      lastStep: 9,
      session: null,
    }
  },
  computed: {
    userData: {
      get() {
        return this.$store.state.user.userData
      },
    },
    step: {
      get() {
        return this.$route.params.step
      },
    },
    postOffer: {
      get() {
        return this.$store.state.postOffer
      },
      set(val) {
        this.$store.commit('setPostOffer', val)
      },
    },
    postOfferStep: {
      get() {
        const nextStep = this.$config.constant.getPostOfferStep(this.postOffer)

        return [...nextStep]
      },
    },
  },
  watch: {
    '$route.path'(path, oldPath) {
      const step = Number(this.step)
      const startOfferFlag = this.postOffer?.startOfferFlag

      this.replacePathname(path, oldPath)

      for (let key = this.postOfferStep.length - 1; 0 <= key; key--) {
        if (this.postOfferStep[key] === 'detail') {
          this.lastStep = Number(key)
          break
        }
      }

      let postOfferPercent = Math.floor(this.step / this.lastStep * 100)
      this.postOfferPercent = postOfferPercent
      this.$store.commit('setGnbBody', {postOfferPercent})

      if (startOfferFlag === 'y') {
        if (step === 1) {
          this.$store.commit('setGnbLeftBtn', 'none')
          this.$store.commit('setGnbLeftBtnCb', () => {})
        } else {
          this.$store.commit('setGnbLeftBtn', false)
          this.$store.commit('setGnbLeftBtnCb', false)
        }
      }
    },
  },
  created() {
    this.replacePathname(this.$route.path)
  },
  beforeRouteEnter (to, from, next) {
    next(vm => cb.call(vm))

    function cb() {
      const startOfferFlag = this.postOffer?.startOfferFlag

      if (startOfferFlag === 'y') {
        this.$store.commit('setGnbRightBtn', 'close')
        this.$store.commit('setGnbRightBtnCb', () => {
          this.exitPostOffer()
        })
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    this.$store.commit('setGnbRightBtn', false)
    this.$store.commit('setGnbRightBtnCb', false)
    this.$store.commit('setGnbLeftBtn', false)
    this.$store.commit('setGnbLeftBtnCb', false)
    next()
  },
  methods: {
    replacePathname(path, oldPath) {
      if (/preview$/.test(path)) return

      const match = path.match(/^\/offer\/post\/(\d+)\/?(.+)?$/)
      const url = match[2]
      let step = match[1]
      let oldMatch, oldStep = 0
      if (oldPath) {
        oldMatch = oldPath.match(/^\/offer\/post\/(\d+)\/?(.+)?$/)
        oldStep = oldMatch[1]
      }

      oldStep = Number(oldStep)
      step = Number(step)

      if (this.postOffer.mode === 'direct' && this.postOfferStep[step] === 'addedInfo') {
        if (oldStep > step) {
          this.$router.back()
        } else {
          this.nextStep()
        }
      } else if (!url) {
        this.$router.replace(path + '/' + this.postOfferStep[step])
      }

      // 아이폰에서 뒤로가기시 스크롤 위치가 변하는 버그 수정. 강제로 이동시킴.
      setTimeout(() => {
        window?.scroll?.({ top: 0, behavior: 'smooth' })
      }, 0)
      setTimeout(() => {
        window?.scroll?.({ top: 0, behavior: 'smooth' })
      }, 500)
    },
    nextStep() {
      if (!this.session) {
        this.session = `${moment().format('YYYYMMDDHHmmss')}_의뢰등록_useridx:${this.userData.useridx}`
      }

      this.$router.push(`/offer/post/${Number(this.step) + 1}`)

      if (this.postOffer.mode === 'direct' && !this.postOffer.artistidx) {
        this.$router.push('/home')
      }
    },
    submitPostOffer() {
      if (this.postOffer.mode === 'direct') {
        FB.AppEvents.logEvent('click', {desc:`일반회원 > 전문가 상세 > 1:1의뢰 > 마지막 페이지의 '등록하기' 버튼 선택 시`})
      } else {
        FB.AppEvents.logEvent('click', {desc:`일반회원 > 메인 > 의뢰등록 > 마지막 페이지의 '등록하기' 버튼 선택 시`})
      }

      this.$store.dispatch('postOffer/submit', this)
        .then((resultData) => {
          if (resultData.success) {
            this.session = null
            this.$router.push('/offer/post/complete')
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
    },
    exitPostOffer() {
      this.$store.commit('setModal', {
        show: true,
        body: `
          <span style="font-size: 24px;font-weight: 600;color: #000;">작성을 중단합니다.</span>

          진행 중인 무료 견적받기를
          그만하시겠어요?
          메인 > 무료견적받기에서
          다시 이용하실 수 있습니다.`,
        textAlign: 'text-center',
        confirm: '중단하기',
        confirmCb: modal => {
          modal.hide()
          this.$router.push('/home')
        },
        cancel: '취소',
        cancelCb: modal => {
          modal.hide()
        },
        noBorder: true,
      })
    },
  }
}
</script>

<style lang="scss">
.post-offer {
  .postOffer-guideLetter {
    color: #5a5a5a;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.5;
    b {
      font-weight: 500;
    }
  }
}
</style>
